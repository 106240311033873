$defaultSize: 10rem;

$iconColor: aqua;

.magnetic-container{
  border: none;
  margin: 0;
  background-color: transparent;
  padding: $defaultSize*0.8;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aqua;
}
.magnetic-element{
  z-index: 2;
  width: $defaultSize * 0.3;
  height: $defaultSize * 0.3;
  img {
    height: 100%;
    vertical-align: bottom;
  }
}
.magnetic-background{
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  pointer-events: none;
  background-color: $iconColor;
  z-index: 1;
  position: absolute;
  width: $defaultSize * 0.5;
  height: $defaultSize * 0.5;
  border-radius: $defaultSize *0.2;
  box-shadow: 0px $defaultSize * 0.4 $defaultSize 0px rgba($iconColor, 0.85);
}

.magnetic-text{
  color: aqua;
}
